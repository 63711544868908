import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primaryColor: "#ee2d3d", // Primary Color
      },
    },
  },
});
