import Vue from "vue";
import VueRouter from "vue-router";
import LoginView from "@/views/LoginView.vue";
import MainLayout from "@/layouts/MainLayout.vue"; // Import the MainLayout

import DashboardView from "@/views/DashboardView.vue";
import FormsView from "@/views/FormsView.vue";
import ReportsView from "@/views/ReportsView.vue";
import UsersView from "@/views/UsersView.vue";
import SettingsView from "@/views/SettingsView.vue";

Vue.use(VueRouter);

const routes = [
	{ path: "/", redirect: "/login" }, // Redirect to dashboard
	{ path: "/login", component: LoginView },
	{
		path: "/dashboard",
		component: MainLayout, // Use MainLayout for dashboard
		children: [
			{ path: "", component: DashboardView }, // DashboardView.vue
			// You can add more child routes if needed
		],
	},
	{
		path: "/forms",
		component: MainLayout, // Use MainLayout for forms
		children: [{ path: "", component: FormsView }], // FormsView.vue
	},
	{
		path: "/reports",
		component: MainLayout, // Use MainLayout for reports
		children: [{ path: "", component: ReportsView }], // ReportsView.vue
	},
	{
		path: "/users",
		component: MainLayout, // Use MainLayout for users
		children: [{ path: "", component: UsersView }], // UsersView.vue
	},
	{
		path: "/settings",
		component: MainLayout, // Use MainLayout for settings
		children: [{ path: "", component: SettingsView }], // SettingsView.vue
	},
];

const router = new VueRouter({
	mode: "history",
	routes,
});

export default router;
