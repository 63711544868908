<template>
	<div>
		<h1 class="mb-4">Dashboard</h1>
		<div class="dashboard-cards">
			<!-- <DashboardCard /> -->
		</div>
		<v-container class="main-section-dashboard" fluid>
			<!-- <v-row>
				<v-col>
					<DashboardBedChart />
				</v-col>
				<v-col>
					<DashboardRecentMoveouts />
				</v-col>
			</v-row> -->
		</v-container>
	</div>
</template>

<script>
	// import DashboardBedChart from "./dashboard-bedchart.vue";
	// import DashboardRecentMoveouts from "./dashboard-recent-moveouts.vue";
	// import DashboardCard from "./dashboard-card.vue";

	export default {
		name: "MainSectionDashboard",
		components: {
			// DashboardCard,
			// DashboardBedChart,
			// DashboardRecentMoveouts,
		},
	};
</script>

<style scoped>
	.main-section-dashboard {
		height: 600px; /* Set a fixed height */
	}

	.main-section-dashboard .v-col {
		display: flex;
		flex-direction: column;
	}

	.main-section-dashboard .v-col > * {
		flex: 1;
	}
</style>
