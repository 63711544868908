<template>
	<div>
		<!-- User Profile -->
		<v-list>
			<v-list-item>
				<v-list-item-avatar>
					<v-img src="@/assets/avatar-default.svg"></v-img>
				</v-list-item-avatar>
				<v-list-item-content>
					<v-list-item-title>Test User</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
		</v-list>

		<v-divider></v-divider>

		<!-- Sidebar Menu -->
		<v-list dense>
			<v-list-item
				v-for="(item, index) in menuItems"
				:key="index"
				@click="selectMenu(item)">
				<v-list-item-icon v-if="showIcons">
					<v-icon>{{ "mdi-" + item.icon }}</v-icon>
				</v-list-item-icon>
				<v-list-item-content>
					<v-list-item-title>{{ item.title }}</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
		</v-list>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				menuItems: [
					{
						title: "Dashboard",
						icon: "view-dashboard",
						route: "/dashboard",
					},
					{
						title: "Forms",
						icon: "file-document",
						route: "/forms",
					},
					{
						title: "Reports",
						icon: "chart-line",
						route: "/reports",
					},
					{
						title: "User Management",
						icon: "account-group",
						route: "/users",
					},
					{
						title: "Settings",
						icon: "cog",
						route: "/settings",
					},
				],
				showIcons: true,
			};
		},
		methods: {
			selectMenu(item) {
				if (this.$route.path !== item.route) {
					this.$emit("menu-selected", item);
					this.$router.push(item.route);
				}
			},
		},
	};
</script>

<style>
	/* Add any custom styles for the dashboard-sidebar component */
</style>
