<template>
	<div id="app">
		<!-- SALVATION ARMY - CHILLIWACK -->
		<router-view></router-view>
	</div>
</template>

<script>
	export default {
		name: "App",
		created() {
			document.title = `Salvation Army » Chilliwack`;
		},
	};
</script>
