<template>
	<div class="settings-view">
		<main-section-settings></main-section-settings>
	</div>
</template>

<script>
	import SettingsView from "@/components/main-section-settings.vue";

	export default {
		components: {
			"main-section-settings": SettingsView,
		},
	};
</script>
