<template>
	<v-app>
		<v-app-bar :elevation="5" app class="theme-color-red white--text">
			<v-breadcrumbs class="white--text">
				<v-breadcrumbs-item> Salvation Army </v-breadcrumbs-item>
				<v-breadcrumbs-divider>
					<v-icon class="white--text"
						>mdi-chevron-double-right</v-icon
					>
				</v-breadcrumbs-divider>
				<!-- Add a divider between "App" and "Test" -->
				<v-breadcrumbs-item> Chilliwack </v-breadcrumbs-item>
			</v-breadcrumbs>
		</v-app-bar>

		<v-main>
			<v-container
				class="background-image fill-height d-flex justify-center align-center"
				fluid>
				<v-row justify="center">
					<v-col cols="12" sm="6" md="3">
						<!-- Alert -->
						<v-snackbar
							v-model="loginAlert"
							color="error"
							class="login-alert">
							<div class="text-center">
								Invalid Credentials. Please try again!
								<v-btn text icon @click="loginAlert = false">
									<v-icon>mdi-close-box-outline</v-icon>
								</v-btn>
							</div>
						</v-snackbar>
						<!-- Login form -->
						<v-card class="ma-2 pa-2 card-border">
							<v-card-title class="headline">
								<!-- Adjust logo size -->
								<v-img
									src="@/assets/salvation-army-logo.png"
									class="mb-3"
									aspect-ratio="1"
									contain
									max-height="200"></v-img>
							</v-card-title>
							<v-card-text>
								<v-form @submit.prevent="login">
									<v-text-field
										v-model="username"
										label="Username"
										prepend-icon="mdi-account"
										required></v-text-field>
									<v-text-field
										v-model="password"
										label="Password"
										:append-icon="
											showPassword
												? 'mdi-eye-off'
												: 'mdi-eye'
										"
										@click:append="
											showPassword = !showPassword
										"
										:type="
											showPassword ? 'text' : 'password'
										"
										prepend-icon="mdi-lock"
										required></v-text-field>
									<v-btn
										block
										color="#ee2d3d"
										class="white--text"
										type="submit">
										<v-icon left>mdi-login</v-icon>
										Login
									</v-btn>
								</v-form>
							</v-card-text>
						</v-card>
					</v-col>
				</v-row>
			</v-container>
		</v-main>
	</v-app>
</template>

<script>
	export default {
		name: "sa-login-form",
		data() {
			return {
				username: "",
				password: "",
				showPassword: false,
				loginAlert: false,
			};
		},
		methods: {
			login() {
				// Perform authentication logic here
				if (this.username === "admin" && this.password === "123") {
					// If authentication succeeds, redirect to dashboard
					console.log("Login successful?");
					this.$router.push("/dashboard"); // Redirect to dashboard route
					console.log("Login successful!");
				} else {
					// If authentication fails, show error message or handle it accordingly
					console.log("Invalid credentials. Please try again.");
					this.loginAlert = true;
				}
			},
		},
	};
</script>

<style scoped>
	.background-image {
		background-image: url("@/assets/salvation-army-login-bg.png");
		background-size: cover;
		background-repeat: no-repeat;
	}
	.card-border {
		border: solid gray 1.5px;
	}
	.login-alert {
		position: block;
		bottom: 0;
		width: 100%;
		z-index: 10;
	}
	.text-center {
		text-align: center !important;
	}
</style>
