<template>
	<v-app-bar app class="theme-color-red">
		<!-- Toggle button for sidebar -->
		<v-btn icon @click="toggleDrawer" style="color: white">
			<v-icon>mdi-menu</v-icon>
		</v-btn>

		<!-- Title -->
		<v-toolbar-title style="color: white">
			Salvation Army
			<v-icon class="white--text">mdi-chevron-double-right</v-icon>
			Chilliwack
		</v-toolbar-title>

		<v-spacer></v-spacer>

		<!-- Search field -->
		<div class="pe-md-3 d-flex align-items-center mr-3">
			<!-- Adjusted margin to make space for buttons -->
			<v-autocomplete
				v-model="searchQuery"
				:items="filteredItems"
				placeholder="Search here..."
				hide-details
				dense
				class="white--text mx-2"
				solo
				background-color="white"
				style="border-radius: 4px"
				append-icon="mdi-magnify"
				@input="onSearchInput"
				@change="showPersonInfo"
				append-icon-cb></v-autocomplete>

			<v-btn
				icon
				@click="showAdmitDialog = true"
				class="mx-5"
				style="
					color: white;
					border: 1px solid white;
					border-radius: 10px;
					background-color: #d32f2f;
					width: 100px;
				">
				<v-icon>mdi-account-plus</v-icon>
				Admit
			</v-btn>
			<v-btn
				icon
				@click="showDischargeDialog = true"
				style="
					color: white;
					border: 1px solid white;
					border-radius: 10px;
					background-color: #d32f2f;
					width: 150px;
				">
				Discharge
				<v-icon>mdi-account-remove</v-icon>
			</v-btn>
		</div>

		<!-- Overflow menu -->
		<v-menu offset-y>
			<template v-slot:activator="{ on, attrs }">
				<v-btn icon v-bind="attrs" v-on="on" style="color: white">
					<v-icon>mdi-dots-vertical</v-icon>
				</v-btn>
			</template>
			<v-list style="color: white">
				<v-list-item @click="logout">Log Out</v-list-item>
			</v-list>
		</v-menu>

		<!-- Admit Dialog -->
		<v-dialog v-model="showAdmitDialog" max-width="500px">
			<v-card>
				<v-card-title>
					<span class="headline">Admit Person</span>
				</v-card-title>
				<v-card-text>
					<v-form ref="admitForm">
						<v-text-field
							v-model="admitForm.name"
							label="Name"
							required></v-text-field>
						<v-text-field
							v-model="admitForm.bedNumber"
							label="Bed Number"
							required></v-text-field>
						<v-text-field
							v-model="admitForm.date"
							label="Admit Date"
							type="date"
							required></v-text-field>
					</v-form>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="blue darken-1"
						text
						@click="showAdmitDialog = false"
						>Cancel</v-btn
					>
					<v-btn class="form-admit-btn" text @click="admit"
						>Admit</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- Discharge Dialog -->
		<v-dialog v-model="showDischargeDialog" max-width="500px">
			<v-card>
				<v-card-title>
					<span class="headline">Discharge Person</span>
				</v-card-title>
				<v-card-text>
					<v-form ref="dischargeForm">
						<v-text-field
							v-model="dischargeForm.name"
							label="Name"
							required></v-text-field>
						<v-text-field
							v-model="dischargeForm.bedNumber"
							label="Bed Number"
							required></v-text-field>
						<v-text-field
							v-model="dischargeForm.date"
							label="Discharge Date"
							type="date"
							required></v-text-field>
						<v-select
							v-model="dischargeForm.reason"
							:items="dischargeReasons"
							label="Reason"
							required></v-select>
					</v-form>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="blue darken-1"
						text
						@click="showDischargeDialog = false"
						>Cancel</v-btn
					>
					<v-btn color="blue darken-1" text @click="discharge"
						>Discharge</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- Person Info Dialog -->
		<v-dialog v-model="showPersonInfoDialog" max-width="500px">
			<v-card>
				<v-card-title>
					<span class="headline">Person Information</span>
				</v-card-title>
				<v-card-text>
					<div v-if="selectedPerson">
						<p><strong>Name:</strong> {{ selectedPerson.name }}</p>
						<p>
							<strong>Bed Number:</strong>
							{{ selectedPerson.bedNumber }}
						</p>
						<p><strong>Date:</strong> {{ selectedPerson.date }}</p>
						<p>
							<strong>Reason:</strong> {{ selectedPerson.reason }}
						</p>
					</div>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="blue darken-1"
						text
						@click="showPersonInfoDialog = false"
						>Close</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-app-bar>
</template>

<script>
	export default {
		data() {
			return {
				searchQuery: "",
				showAdmitDialog: false,
				showDischargeDialog: false,
				showPersonInfoDialog: false,
				admitForm: {
					name: "",
					bedNumber: "",
					date: "",
				},
				dischargeForm: {
					name: "",
					bedNumber: "",
					date: "",
					reason: "",
				},
				dischargeReasons: ["Recovered", "Transferred", "Deceased"],
				selectedPerson: null,
				sampleData: [
					{
						name: "John Doe",
						bedNumber: 12,
						date: "May 15, 2024",
						reason: "Recovered",
					},
					{
						name: "Jane Smith",
						bedNumber: 34,
						date: "May 14, 2024",
						reason: "Transferred",
					},
					{
						name: "Alan Johnson",
						bedNumber: 56,
						date: "May 13, 2024",
						reason: "Deceased",
					},
					{
						name: "Will Smith",
						bedNumber: 34,
						date: "May 14, 2024",
						reason: "Transferred",
					},
					{
						name: "Jenny Lopez",
						bedNumber: 34,
						date: "May 14, 2024",
						reason: "Transferred",
					},
					{
						name: "Chris Brown",
						bedNumber: 34,
						date: "May 14, 2024",
						reason: "Transferred",
					},
					{
						name: "Will IAm",
						bedNumber: 34,
						date: "May 14, 2024",
						reason: "Transferred",
					},
					{
						name: "Penelope Featherington",
						bedNumber: 34,
						date: "May 14, 2024",
						reason: "Transferred",
					},
					{
						name: "Colin Bridgerton",
						bedNumber: 34,
						date: "May 14, 2024",
						reason: "Transferred",
					},
				],
			};
		},
		methods: {
			toggleDrawer() {
				this.$emit("toggle-drawer"); // Emitting the event to toggle sidebar
			},
			admit() {
				console.log("Admit:", this.admitForm);
				this.showAdmitDialog = false;
				this.admitForm = { name: "", bedNumber: "", date: "" };
				this.$notify({
					type: "success",
					title: "Successfully Admitted",
					text: `Admitted on: ${new Date().toLocaleDateString()}`,
				});
			},
			discharge() {
				console.log("Discharge:", this.dischargeForm);
				this.showDischargeDialog = false;
				this.dischargeForm = {
					name: "",
					bedNumber: "",
					date: "",
					reason: "",
				};
				this.$notify({
					type: "success",
					title: "Successfully Discharged",
					text: `Discharged on: ${new Date().toLocaleDateString()}`,
				});
			},
			onSearchInput() {
				if (this.searchQuery.length >= 3) {
					this.filteredItems = this.sampleData.filter((item) =>
						item.name
							.toLowerCase()
							.includes(this.searchQuery.toLowerCase())
					);
				} else {
					this.filteredItems = [];
				}
			},
			showPersonInfo(person) {
				this.selectedPerson = person;
				this.showPersonInfoDialog = true;
			},
			logout() {
				console.log("Logging out");
				this.$router.push("/login");
			},
		},
		computed: {
			filteredItems() {
				if (this.searchQuery.length < 3) return [];
				return this.sampleData.filter((item) =>
					item.name
						.toLowerCase()
						.includes(this.searchQuery.toLowerCase())
				);
			},
		},
	};
</script>

<style scoped>
	.pe-md-3 {
		padding-right: 1rem; /* Adjust as needed */
	}
	.d-flex {
		display: flex;
	}
	.align-items-center {
		align-items: center;
	}
	.mx-2 {
		margin-left: 0.5rem;
		margin-right: 0.5rem;
	}
	.v-text-field--solo {
		background-color: white !important;
	}
	.v-icon.mdi-magnify {
		color: #d32f2f !important; /* Red color for magnify icon */
	}
	.v-autocomplete input {
		color: #d32f2f !important; /* Red color for input text */
	}
</style>
