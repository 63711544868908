<template>
	<div class="login-view">
		<main-section-login></main-section-login>
	</div>
</template>

<script>
	import LoginView from "@/components/main-section-login.vue";

	export default {
		components: {
			"main-section-login": LoginView,
		},
	};
</script>
