<template>
	<div class="dashboard-view">
		<main-section-dashboard></main-section-dashboard>
	</div>
</template>

<script>
	import DashboardView from "@/components/main-section-dashboard.vue";

	export default {
		components: {
			"main-section-dashboard": DashboardView,
		},
	};
</script>

<style scoped>
	/* Add your scoped styles here */
</style>
