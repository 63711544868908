<template>
	<div class="users-view">
		<main-section-users></main-section-users>
	</div>
</template>

<script>
	import UsersView from "@/components/main-section-users.vue";

	export default {
		components: {
			"main-section-users": UsersView,
		},
	};
</script>
