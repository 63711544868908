import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
// import "@mdi/font/css/materialdesignicons.css";
import router from "./router/router"; // Import the router

Vue.config.productionTip = false;

new Vue({
	vuetify,
	router, // Add the router to your Vue instance
	render: (h) => h(App),
}).$mount("#app");
