<template>
	<div class="reports-view">
		<main-section-reports></main-section-reports>
	</div>
</template>

<script>
	import ReportsView from "@/components/main-section-reports.vue";

	export default {
		components: {
			"main-section-reports": ReportsView,
		},
	};
</script>

<style scoped>
	/* Add your scoped styles here */
</style>
